<template>
  <div>
    <div class="row">
      <div class="col-xxl-8">
        <div class="card card-custom gutter-b example example-compact" v-if="isReady">
          <!--begin::Header-->
          <div class="card-header pb-0">
            <div class="card-title">
              <!---->
              <h3 class="card-label">{{ $t('Project_map.post_detail') }} (Thai)</h3>
            </div>
            <div class="card-toolbar">
              <div class="example-tools justify-content-center"></div>
            </div>
          </div>
          <!--end::Header-->
          <!--begin::Body-->
          <div class="card-body pt-3 pb-0">
            <b-form-group id="fieldset-1" description="" :label="$t('Project_map.title')" label-for="input-1">
              <b-form-input
                id="input-1"
                v-model="post.title_th"
                trim
                placeholder="untitle"
                :state="!(post.title_th == '')"
              ></b-form-input>
            </b-form-group>

            <b-form-group id="fieldset-1" description="" :label="$t('Project_map.body')" label-for="input-1">
              <ckeditor :editor="editor" v-model="post.body_th" :config="editorConfig"></ckeditor>
            </b-form-group>
          </div>
        </div>

        <div class="card card-custom gutter-b example example-compact" v-if="isReady">
          <!--begin::Header-->
          <div class="card-header pb-0">
            <div class="card-title">
              <!---->
              <h3 class="card-label">{{ $t('Project_map.post_detail') }} (English)</h3>
            </div>
            <div class="card-toolbar">
              <div class="example-tools justify-content-center"></div>
            </div>
          </div>
          <!--end::Header-->
          <!--begin::Body-->
          <div class="card-body pt-3 pb-0">
            <b-form-group id="fieldset-1" description="" :label="$t('Project_map.title')" label-for="input-1">
              <b-form-input
                id="input-1"
                v-model="post.title_en"
                trim
                placeholder="untitle"
                :state="!(post.title_en == '')"
              ></b-form-input>
            </b-form-group>

            <b-form-group id="fieldset-1" description="" :label="$t('Project_map.body')" label-for="input-1">
              <ckeditor :editor="editor" v-model="post.body_en" :config="editorConfig"></ckeditor>
            </b-form-group>
          </div>
        </div>

        <div class="card card-custom gutter-b example example-compact" v-if="isReady && !!post.attachment">
          <!--begin::Header-->
          <div class="card-header pb-0">
            <div class="card-title">
              <!---->
              <h3 class="card-label">{{ $t('Project_map.images') }}</h3>
            </div>
            <div class="card-toolbar">
              <div class="example-tools justify-content-center"></div>
            </div>
          </div>
          <!--end::Header-->
          <!--begin::Body-->
          <div class="card-body pt-3 pb-0">
            <b-container fluid class="p-4">
              <b-row class="mb-5" v-for="i in Math.ceil(post.attachment.length / 4)" :key="i">
                <b-col v-for="item in post.attachment.slice((i - 1) * 4, i * 4)" :key="item.id">
                  <b-img
                    thumbnail
                    fluid
                    :src="item.path"
                    v-clipboard:copy="item.path"
                    @click="copyImageURL('attach_' + item.id)"
                    :id="'attach_' + item.id"
                  >
                  </b-img>
                  <b-tooltip :show="false" :ref="'attach_' + item.id" :target="'attach_' + item.id" variant="primary"
                    >Click to Copy url</b-tooltip
                  >
                </b-col>
              </b-row>
            </b-container>
          </div>
        </div>
        <b-overlay :show="isSaving" no-wrap> </b-overlay>
      </div>

      <div class="col-xxl-4">
        <div class="card card-custom gutter-b" v-if="isReady">
          <!--begin::Header-->
          <div class="card-header pb-0">
            <div class="card-title">
              <!---->
              <h3 class="card-label">
                {{ $t('Project_map.publish') }}
              </h3>
            </div>
            <div class="card-toolbar">
              <div class="example-tools justify-content-center"></div>
            </div>
          </div>
          <!--end::Header-->
          <!--begin::Body-->
          <div class="card-body pt-3 pb-0">
            <b-form-group id="fieldset-1" label="สถานะ  " label-for="input-1">
              <b-form-select v-model="post.status" :options="status_list"></b-form-select>
            </b-form-group>
            <b-button variant="primary" class="mb-5" block @click="savePost" v-if="post.status == 0">Save</b-button>
            <b-button variant="success" class="mb-5" block @click="savePost" v-else>Publish</b-button>
          </div>
          <!--end::Body-->
          <b-overlay :show="isSaving" no-wrap> </b-overlay>
        </div>

        <div class="card card-custom gutter-b" v-if="isReady">
          <!--begin::Header-->
          <div class="card-header pb-0">
            <div class="card-title">
              <!---->
              <h3 class="card-label">
                {{ $t('Project_map.parent_project') }}
              </h3>
            </div>
            <div class="card-toolbar">
              <div class="example-tools justify-content-center"></div>
            </div>
          </div>
          <!--end::Header-->
          <!--begin::Body-->
          <div class="card-body pt-3 pb-0">
            <b-form-group id="fieldset-1" :label="$t('Project_map.parent_project')" label-for="input-1">
              <b-form-select v-model="post.parent_id" :options="parent_list"></b-form-select>
            </b-form-group>
          </div>
          <!--end::Body-->
          <b-overlay :show="isSaving" no-wrap> </b-overlay>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xxl-12">
        <div class="card card-custom gutter-b" v-if="isReady">
          <div class="card-header border-0 pt-5">
            <h3 class="card-title align-items-start flex-column">
              <span class="card-label font-weight-bolder text-dark">
                {{ $t('Project_map.project_position') }}
              </span>
              <span class="text-muted mt-3 font-weight-bold font-size-sm">
                {{ $t('GENERAL.TOTAL_OF_LIST', [total_post ? total_post : 0]) }}
              </span>
            </h3>

            <div class="card-toolbar">
              <ul class="nav nav-pills nav-pills-sm nav-dark-75">
                <li>
                  <router-link :to="{name: 'project_map.add'}" class="btn btn-sm btn-info">
                    {{ $t('Project_map.add') }}
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
          <!--begin::Body-->
          <div class="card-body pt-3 pb-0">
            <b-table :items="positions" head-variant="light" :fields="table_fields">
              <template #cell(name)="data">
                {{ data.value }}
              </template>
              <template #cell(lat)="data">
                {{ data.value }}
              </template>
              <template #cell(lon)="data">
                {{ data.value }}
              </template>
              <template #cell(options)="" style="width: 5%">
                <b-button variant="danger" class="btn-sm pt-0 pb-0 pr-2 pl-2 float-right">x</b-button>
              </template>
            </b-table>
          </div>
          <!--end::Body-->
          <b-overlay :show="isSaving" no-wrap> </b-overlay>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {SET_BREADCRUMB} from '@/core/services/store/breadcrumbs.module';
import {SAVE_POST, QUERY, GET_POSITION, UPLOAD_FILE} from '../services.js';

import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';

import Essentials from '@ckeditor/ckeditor5-essentials/src/essentials';
import Autoformat from '@ckeditor/ckeditor5-autoformat/src/autoformat';
import Bold from '@ckeditor/ckeditor5-basic-styles/src/bold';
import Italic from '@ckeditor/ckeditor5-basic-styles/src/italic';
import Heading from '@ckeditor/ckeditor5-heading/src/heading';
import Link from '@ckeditor/ckeditor5-link/src/link';
import Paragraph from '@ckeditor/ckeditor5-paragraph/src/paragraph';
import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment';

export default {
  name: 'List',
  components: {},
  data() {
    return {
      isReady: false,
      isSaving: false,
      isUploading: false,
      mode: null,
      post: {
        id: 0,
        title_th: '',
        body_th: '',
        title_en: '',
        body_en: '',
        parent_id: 0,
        image_path: '',
        image_attachment: [],
        status: 0,
      },
      total_post: 0,
      pictureFile: null,
      attachFiles: [],
      imageSrc: null,
      parent_list: [{value: 0, text: 'Default'}],
      researcher_list: [{value: 0, text: 'No researcher'}],
      status_list: [
        {
          value: 0,
          text: 'Draft',
        },
        {
          value: 1,
          text: 'Published',
        },
      ],
      editor: ClassicEditor,
      editorConfig: {
        plugins: [Essentials, Autoformat, Bold, Italic, Heading, Link, Paragraph, Alignment],

        toolbar: {
          items: ['bold', 'italic', 'undo', 'redo'],
        },
      },
      table_fields: ['name', 'lat', 'lon', 'options'],
      positions: [
        {
          name: 'จุดตรวจที่ 1',
          lat: '16.7367753',
          lon: '100.0583561',
        },
        {
          name: 'จุดตรวจที่ 2',
          lat: '16.7367753',
          lon: '100.0583561',
        },
      ],
    };
  },
  mounted() {
    //get category
    this.getData();

    this.$store.dispatch(SET_BREADCRUMB, [{title: 'Project Map', route: '../'}, {title: 'Edit'}]);
  },
  methods: {
    getData() {
      // this.$store
      //   .dispatch(GET_POSITION)
      //   // go to which page after successfully login
      //   .then((res) => {
      //     res.data.forEach((parent) => {
      //       if (parent.id != this.post.id) {
      //         this.parent_list.push({
      //           value: parent.id,
      //           text: parent.title,
      //         });
      //       }
      //     });
      //   })
      //   .catch((error) => {
      //     // console.log(error);
      //   });
      if (this.$route.name == 'project_map.edit') {
        this.mode = 'edit';
        this.id = this.$route.params.id;
        this.$store
          .dispatch(QUERY, {id: this.id})
          // go to which page after successfully login
          .then((res) => {
            this.post = res.data[0];

            this.isReady = true;
          })
          .catch((error) => {
            // console.log(error);
          });
      } else {
        this.isReady = true;
        this.mode = 'add';
      }
    },
    savePost(mode) {
      return new Promise((resolve, reject) => {
        if (this.post.cat_id <= 0 || this.post.title_th == '' || this.post.title_en == '') {
          this.notify('danger', 'Error!', 'Please complete the form');
          reject('Please complete the form');
        } else {
          this._savePost(mode);
          resolve();
        }
      });
    },
    _savePost(mode) {
      this.isSaving = true;
      this.$store
        .dispatch(SAVE_POST, this.post)
        // go to which page after successfully login
        .then((res) => {
          if (res.status) {
            this.post = res.data[0];

            //upload banner
            if (this.pictureFile) {
              let formData = new FormData();
              formData.append('file', this.pictureFile);
              this.pictureFile = null;
              this.$store
                .dispatch(UPLOAD_FILE, formData)
                // go to which page after successfully login
                .then((res) => {
                  if (res.status) {
                    this.post.image_path = res.data.image_path;
                  }
                  // console.log('Upload Banner');
                  this.isSaving = false;
                  this.pictureFile = null;
                  //Update image path
                  this.savePost('update_image');
                })
                .catch((error) => {
                  this.isSaving = false;
                  // console.log(error);
                });
            }

            // this.uploadFile();
            if (this.$route.name == 'project_map.add') {
              this.$router.push({
                name: 'project_map.edit',
                params: {id: this.post.id},
              });
            }
          }
          // console.log('Save post');
          this.isSaving = false;
          if (mode != 'update_image') {
            this.notify('success', 'Successfully!', 'Post Saved.');
            if (this.post.status == 1) {
              this.$router.push({
                name: 'project_map.list',
              });
            }
          }
        })
        .catch((error) => {
          this.isSaving = false;
          // console.log(error);
        });
    },
    notify(variant = null, title, text) {
      this.$bvToast.toast(text, {
        title: title,
        variant: variant,
        solid: true,
      });
    },
  },
  computed: {},
  watch: {},
};
</script>

<style lang="scss" scoped>
#preview {
  display: flex;
  justify-content: center;
  align-items: center;
}

#preview img {
  max-width: 100%;
  max-height: 500px;
}
</style>
